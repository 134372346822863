<template>
  <div class="template-detail">
    <el-form class="medium-form" v-loading="loading" :model="config" :rules="rules" label-width="75px"
      size="medium" @submit.native.prevent>
      <el-form-item label="名称：" prop="name">
        <el-input v-model="config.name" placeholder="请输入模板名称"></el-input>
      </el-form-item>
      <el-form-item label="内容：" prop="content">
        <!--      <el-input v-model="config.content" style="min-height: unset;"-->
        <!--                type="textarea" :rows="16" placeholder="请输入内容" resize="vertical"></el-input>-->
        <tinymce ref="editor" v-model="config.content" :height="400" @choose="handleClick"></tinymce>
      </el-form-item>
      <el-form-item label="显示：" prop="status">
        <el-radio-group v-model="config.status">
          <el-radio :label="1">显示</el-radio>
          <el-radio :label="0">不显示</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <!-- 富文本插入图片 -->
    <media-selector v-model="showImageList" multiple @select="updateImages"></media-selector>
    <media-selector v-model="showVideoList" type="video" multiple @select="updateVideos"></media-selector>
    <media-selector v-model="showAudioList" type="audio" multiple @select="updateAudios"></media-selector>
    <!-- 文章导入 -->
    <import-article v-model="isShowImportDialog" @confirm="getImportDetail"></import-article>
    <import-html-code v-model="showCodeInput"></import-html-code>
    <fixed-action-bar :z-index="2">
      <el-button type="primary" :loading="loading" @click="onSave">保存</el-button>
      <el-button @click="onCancel">取消</el-button>
    </fixed-action-bar>
  </div>
</template>

<script>
  import FixedActionBar from "../../../../base/layout/FixedActionBar";
  import {getTemplateDetail, saveTemplate} from "../../api/article-template";
  import MediaSelector from "../../../common/components/MediaSelector";

  import Tinymce from "@/base/components/Editor/Tinymce";
  import ImportHtmlCode from "@/base/components/Editor/ImportHtmlCode";
  import ImportArticle from "@/base/components/Editor/ImportArticle";

  export default {
  components: {
    ImportArticle,
    MediaSelector,
    ImportHtmlCode,
    FixedActionBar,
    Tinymce,
  },
  data() {
    return {
      loading: true,
      config: {
        id: 0,
        name: "",
        content: "",
        status: 1,
      },
      rules: {
        name: [{ required: true, message: "请输入模板名称", trigger: "blur" }],
        content: [{ required: true, message: "请输入内容", trigger: "blur" }],
      },
      showImageList: false,
      showVideoList: false,
      showAudioList: false,
      isShowImportDialog: false,
      showCodeInput: false,
    };
  },
  created() {
    this.config.id = this.$route.params.id;
    if (this.config.id) {
      getTemplateDetail(this.config.id)
        .then((res) => {
          this.config = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  },
  methods: {
    /**
     * 逐一向富文本编辑器插入来自媒体库的图片
     * @param {Array} e 选中的图片数组
     */
    updateImages(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          "mceReplaceContent",
          false,
          `<img src="${item.url}"/>`
        );
      });
    },
    updateVideos(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          "mceReplaceContent",
          false,
          `<p><span class="mce-preview-object mce-object-video"
                contenteditable="false"
                data-mce-object="video"
                data-mce-p-controls="controls"
                data-mce-p-controlslist="nodownload"
                data-mce-p-allowfullscreen="true"
                data-mce-p-frameborder="no"
                data-mce-p-scrolling="no"
                data-mce-html=""
                data-mce-p-src="${item.url}">
                <video controls src="${item.url}"></video></span></p>
                <p><br/></p>`
          // `<video poster="${item.config.thumbnail}" controls src="${item.url}"></video>`
        );
      });
    },
    updateAudios(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          "mceReplaceContent",
          false,
          `<p><span class="mce-object-audio"
            contenteditable="false"
            data-mce-object="audio"
            data-mce-p-controls="controls"
            data-mce-p-controlslist="nodownload"
            data-mce-p-frameborder="no"
            data-mce-p-scrolling="no"
            data-mce-html=""
            data-mce-p-src="${item.url}">
            <audio controls src="${item.url}"></audio></span></p>
            <p><br/></p>`
        );
      });
    },
    getImportDetail(e) {
      this.$set(this.config, "content", e.content);
      this.$refs.editor.$emit("change", e.content);
    },
    handleClick(e) {
      switch (e.type) {
        case "image":
          this.showImageList = true;
          break;
        case "video":
          this.showVideoList = true;
          break;
        case "audio":
          this.showAudioList = true;
          break;
        case "import":
          this.isShowImportDialog = true;
          break;
        case "html":
          this.showCodeInput = true;
          break;
      }
    },
    onSave() {
      this.loading = true;
      saveTemplate(this.config)
        .then((res) => {
          this.$message.success(res.msg);
          this.$router.push({ name: "CustomArticleTmplList" });
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    onCancel() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.template-detail {
  .medium-form .el-textarea {
    max-width: 690px;
    height: unset;
  }
}
</style>

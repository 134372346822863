import api from "@/base/utils/request";

// 模板分类列表
export const getTmplCategories = () => {
  return api({
    url: '/admin/portal/article/tmplCategories',
    method: 'post'
  })
}

// 获取某分类下的模板列表（含默认）
export const getTmplList = (data) => {
  return api({
    url: '/admin/portal/article/tmpls',
    method: 'post',
    data: {
      page_size: 10,
      ...data
    }
  })
}

// 已收藏的模板列表
export const getCollectedTmplList = (data) => {
  return api({
    url: '/admin/portal/article/collectTmpls',
    method: 'post',
    data: {
      page_size: 10,
      ...data
    }
  })
}


// 自定义模板列表（个人模板）
export const getCustomTmplList = (data) => {
  return api({
    url: '/admin/portal/article/customTmpls',
    method: 'post',
    data: {
      page_size: 10,
      ...data
    }
  })
}

/**
 * 收藏某个模板
 */
export const collectTemplate = (data) => {
  return api({
    url: '/admin/portal/article/collectTmpl',
    method: 'post',
    data
  })
}


/**
 * 模板管理
 * @param data
 */

export const getList = (data) => {
  return api({
    url: '/admin/portal/custom_article_tmpl/index',
    method: 'post',
    data
  })
}

export const updateTemplateSort = (data) => {
  return api({
    url: '/admin/portal/custom_article_tmpl/updateSort',
    method: 'post',
    data
  })
}

export const updateTemplateStatus = (data) => {
  return api({
    url: '/admin/portal/custom_article_tmpl/updateStatus',
    method: 'post',
    data
  })
}

export const softDel = (ids) => {
  return api({
    url: '/admin/portal/custom_article_tmpl/softDelete',
    method: 'post',
    data: {ids}
  })
}

export const trueDel = (ids) => {
  return api({
    url: '/admin/portal/custom_article_tmpl/del',
    method: 'post',
    data: {ids}
  })
}

export const putBack = (ids) => {
  return api({
    url: '/admin/portal/custom_article_tmpl/putBack',
    method: 'post',
    data: {ids}
  })
}

/**
 * 新增/编辑
 */
export const saveTemplate = (data) => {
  return api({
    url: '/admin/portal/custom_article_tmpl/save',
    method: 'post',
    data
  })
}

export const getTemplateDetail = (id) => {
  return api({
    url: '/admin/portal/custom_article_tmpl/detail',
    method: 'post',
    data: {id}
  })
}
